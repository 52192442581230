<template>
    <div>
       
            <van-nav-bar title="我的"/>
            <div class="titleLineDiv" @click="goToPerson">
                <div>
                    个人中心
                </div>
                <img src="../../assets/image/loder/right.png" alt="">
            </div>
            <div class="titleLineDiv" @click="accountManage">
                <div>
                    帐号管理
                </div>
                <img src="../../assets/image/loder/right.png" alt="">
            </div>
            <button class="loginOut" @click="loginOut">退出登录</button>
        
       
     
    </div>
</template>
<script>
    export default{
        name:'Main',
        data(){
            return{
               
            }
        },
        methods:{
            goToPerson(){
                this.$toast.fail('夜以继日开发中,请期待');
            },
            accountManage(){
                this.$toast.fail('夜以继日开发中,请期待');
            },
            loginOut(){
                this.$dialog.alert({
                        title: '退出',
                        message: '您确定要退出登录吗?',
                        theme: 'round-button',
                        showCancelButton:true,
                        confirmButtonColor:'#06B2B2',
                        cancelButtonColor:'#dddddd'
                    }).then(() => {
                        sessionStorage.clear();
                        this.$router.replace('/login');
                    });
                    

                   
              
            }
        },
        mounted(){
            document.querySelector('body').setAttribute('style','background-color:#F7F7F7')
        },
        beforeDestroy(){
            document.querySelector('body').setAttribute('style','background-color:#ffffff')
        },
   
       
    }
</script>
<style scoped>
.titleLineDiv{
    margin-top: 10px;
    height: 100px;
    background: #ffffff;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.titleLineDiv div{
    margin-left:26px;
    height: 34px;
    font-size: 32px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #333333;
}
.titleLineDiv img{
    width: 14px;
    margin-right: 67px;
}
.loginOut{
    width: 80%;
    height: 80px;
    background: #06B2B2;
    border-radius: 40px;
    font-size: 32px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    line-height:80px;
    margin: 120px auto;
    display: block;
}
</style>
